<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入用户id / 账号"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <div>
          注册时间：
          <el-date-picker
            size="medium"
            v-model="searchForm.startTime"
            type="date"
            value-format="timestamp"
            @change="getTableList"
            placeholder="开始时间"
          ></el-date-picker>
          <span> — </span>
          <el-date-picker
            size="medium"
            v-model="searchForm.endTime"
            type="date"
            value-format="timestamp"
            @change="getTableList"
            placeholder="结束时间"
          ></el-date-picker>
        </div>
        <div>
          <el-select
            clearable
            size="medium"
            v-model="searchForm.source"
            placeholder="数据来源"
          >
            <el-option label="net" value="net"></el-option>
            <el-option label="ai" value="free"></el-option>
            <el-option label="appstore" value="appstore"></el-option>
            <el-option label="co" value="co"></el-option>
            <el-option label="club" value="club"></el-option>
          </el-select>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">

      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="id" label="用户id"></el-table-column>
      <el-table-column prop="nickName" label="昵称"></el-table-column>
      <el-table-column prop="wallet" label="绑定钱包"></el-table-column>
      <el-table-column prop="talkAmount" label="talk余额"></el-table-column>
      <el-table-column prop="totalCost" label="消费金额"></el-table-column>
      <el-table-column prop="backpackInventory" label="背包库存"></el-table-column>
      <!-- <el-table-column prop="nftCount" label="NFT数量"></el-table-column> -->
      <!-- <el-table-column prop="level" label="用户等级"></el-table-column> -->
      <el-table-column prop="totalFinishTask" label="完成任务数量"></el-table-column>
      <el-table-column prop="googleEmail" label="google邮箱"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="shareUserId" label="邀请人ID"></el-table-column>
      <el-table-column label="是否进入过房间">
        <template slot-scope="scope">
          <span>{{scope.row.joinRoom?'是':'-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="数据来源">
        <template slot-scope="scope">
          <span>{{!scope.row.source?'net':scope.row.source=='free'?'ai':scope.row.source}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="注册时间" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.createTime | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.updateTime | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <!-- <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          > -->
          <el-button size="small" type="info" @click="goInfo(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog title="编辑" :visible.sync="addVisible" width="650px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号码" prop="giftName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.giftName"
          ></el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {status:0},
      searchForm: {
        name:undefined,
        pageNo: 1,
        pageSize: 10,
        sortOrder: 'descend',
        sortField: 'createTime'
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination},
  created() {
    this.getTableList();
  },
  methods: {
    goInfo(row){
      this.$router.push({name:'userInfo',query:{id:row.id}})
    },
    toEdit(data) {
      this.ruleForm = {...data}
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.giftSave({...this.ruleForm});
          if (res) {
            this.$message.success("修改成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    changeSort(val) {
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
      }
      this.getTableList(true);
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.getUserList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.giftDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
